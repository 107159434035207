import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-request-successful',
  templateUrl: './request-successful.component.html',
  styleUrls: ['./request-successful.component.scss']
})
export class RequestSuccessfulComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  heading;
  msg;

  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        this.heading = params['h'];
        this.msg = params['msg'];
      }
    )
  }

}
