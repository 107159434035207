import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
    constructor(private translate: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let lang = this.translate.currentLang;
            request = request.clone({
                setHeaders: {
                  'Accept-Language': `${lang}`
                }
            });
        return next.handle(request);
    }
}
