import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/shared';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    public translate: TranslateService
  ) { }

  catList = [];
  subscribeForm: FormGroup;
  isSubmitted = false;
  isLoading$ = false;
  errorMsg: string = null;
  successMsg: string = null;
  ngOnInit(): void {
    this.subscribeForm = this.fb.group({
      email:['', [Validators.required, Validators.email]]
    });
  }

  public get emailCtrl(): FormControl{
    return this.subscribeForm.get('email') as FormControl;
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isLoading$ = true;
    this.subscribeForm.valid &&
    this.contactService.mailSubscribe(this.subscribeForm.value)
    .subscribe(done =>{
      this.successMsg = done.message;
      this.isLoading$ = false;
    },
    error => {
      this.errorMsg = error.message;
      this.isLoading$ = false;
    })
  }

}
