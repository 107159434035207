<div class="header-wrapper" [ngClass]="{'ar': translate.currentLang === 'ar'}">

  <!-- mobile nav-->
  <div class="nav-overlay" *ngIf="mobileMenuFlag">
    <div class="nav-container">
      <nav>
        <ul class="mobile-nav">
          <li><a href="#about-section" (click)="onMenuTrigger()">{{ 'GENERAL.ABOUT' | translate}}</a></li>
          <li><a href="#what-we-do" (click)="onMenuTrigger()">What we do</a></li>
          <li><a href="#activities" (click)="onMenuTrigger()">our Activities</a></li>
          <li><a href="#contact" (click)="onMenuTrigger()">{{ 'GENERAL.CONTACT_US' | translate}}</a></li>
          <li><a [routerLink]="['/entertainment-projects']" routerLinkActive="link-active" (click)="onMenuTrigger()">Entertainment Projects</a></li>
          <li><a [routerLink]="['/investment-opportunities']" routerLinkActive="link-active" (click)="onMenuTrigger()">Investment Opportunities</a></li>
        </ul>
      </nav>
      <hr>
      <!-- <div class="lang">
        <span>Change language to</span>
        <a (click)="changeLang()">
          {{translate.currentLang === "ar"? 'English' : 'العربية'}}
        </a>
      </div> -->
    </div>
  </div>

  <div class="header-container">

    <div class="search-section" >
      <div class="menu-icon" (click)="onMenuTrigger()">
        <ng-container *ngIf="!mobileMenuFlag; else mobileMenuTrigger">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </ng-container>
        <ng-template #mobileMenuTrigger>
          <i class="fa fa-close" aria-hidden="true"></i>
        </ng-template>
      </div>
      <div class="logo">
        <a [routerLink]="['/']">
            <img src="assets/images/logos/logoo.svg" style="width: 200px;"/>
        </a>
      </div>
      <div class="nav-wrapper">
        <nav>
          <ul class="nav-list" style="color: white;">
            <li><a href="#about-section">{{ 'GENERAL.ABOUT' | translate}}</a></li>
            <li><a href="#what-we-do" >What we do</a></li>
            <li><a href="#activities" >our Activities</a></li>
            <li><a href="#contact">{{ 'GENERAL.CONTACT_US' | translate}}</a></li>
            <li><a [routerLink]="['/entertainment-projects']" routerLinkActive="link-active">Entertainment Projects</a></li>
          <li><a [routerLink]="['/investment-opportunities']" routerLinkActive="link-active">Investment Opportunities</a></li>
          </ul>
        </nav>

        <!-- <ul class="cart-lang">
          <li class="cart-btn">
          </li>
          <li class="lang-btn" (click)="changeLang()">
            <a >
              {{translate.currentLang === "ar"? 'English' : 'العربية'}}
            </a>
          </li>
        </ul> -->

      </div>
    </div>



  </div>

</div>
