<div class="wrapper">
  <div class="hero-wrapper" [ngClass]="{'ar': translate.currentLang === 'ar'}">



    <owl-carousel-o [options]="customOptions">

      <ng-template carouselSlide>
        <div class="slide-content">
          <div class="container">
            <div class="text-wrapper">
              <h2>{{'HOME.SLIDES.TITLE1' | translate}}</h2>
              <p>
                {{'HOME.SLIDES.TEXT1' | translate}}
              </p>
            </div>
          </div>
          <picture>
            <source media="(max-width: 576px)" srcset="assets/images/home-page/hero/tab/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <source media="(max-width: 768px)" srcset="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <img src="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg" alt="camera canon capture natural view">
          </picture>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide-content">
          <div class="container">
            <div class="text-wrapper">
              <h2>{{'HOME.SLIDES.TITLE2' | translate}}</h2>
              <p>
                {{'HOME.SLIDES.TEXT2' | translate}}
              </p>
            </div>
          </div>
          <picture>
            <source media="(max-width: 576px)" srcset="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <source media="(max-width: 768px)" srcset="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <img src="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg" alt="camera canon capture natural view">
          </picture>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide-content">
          <div class="container">
            <div class="text-wrapper">
              <h2>{{'HOME.SLIDES.TITLE3' | translate}}</h2>
              <p>
                {{'HOME.SLIDES.TEXT3' | translate}}
              </p>
            </div>
          </div>
          <picture>
            <source media="(max-width: 576px)" srcset="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <source media="(max-width: 768px)" srcset="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg">
            <img src="assets/images/home-page/hero/WhatsApp Image 2021-07-05 at 12.49.26 PM (1).jpeg" alt="camera canon capture natural view">
          </picture>
        </div>
      </ng-template>
    </owl-carousel-o>

  </div>

</div>
