import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-do-egypt',
  templateUrl: './we-do-egypt.component.html',
  styleUrls: ['./we-do-egypt.component.scss']
})
export class WeDoEgyptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
