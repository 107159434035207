<div class="wrapper">
  <div class="container">
    <header>
      <a [routerLink]="['/']">
        <img src="assets/images/logos/logo.png" alt=""></a>
    </header>
    <div class="main">
        <div class="logo-container">
            <h3>404</h3>
          </div>
          <h3>Page not found</h3>
          <a class="btn active-btn" [routerLink]="['/']" >back to home</a>
    </div>
  </div>

</div>
