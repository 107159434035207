<div class="clients-wrapper" >
  <header>
    <h2>Types of Entertainment & Mixed Real Estate Projects </h2>
  </header>

   <div class="container">

    <div class="carousal-wrapper">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Indoor & Outdoor Theme Parks</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Water Parks</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Hotels, Resorts & Residentiual</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Shopping Center</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Wellness & Sport Center</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
              <div class="card-body">
                <h4 class="card-title text-center">Yachting Club</h4>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
  <!-- <div class="container">
    <div class="carousal-wrapper">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/real estate.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Real Estate Projects</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/power and energy.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Power & Energy</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/oil and gaz.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Oil & Gas</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/Information Technology.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Information Technology</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/Healthcare & Wellness.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Healthcare & Wellness</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/Sewage Treatment.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Sewage Treatment</h4>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="container">
            <div class="card" >
              <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%">
              <div class="card-body">
                <h4 class="card-title text-center">Manufacturing</h4>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <div class="view-all-container">
        <a [routerLink]="['/clients']" routerLinkActive="router-link-active" > view all clients</a>
      </div>
    </div>
  </div> -->

</div>
