<section>
  <app-hero-section></app-hero-section>
</section>
<section>
  <app-about-section></app-about-section>
</section>
<section>
  <app-what-we-do-section></app-what-we-do-section>
</section>
<section>
  <app-activities-section></app-activities-section>
</section>
<section>
  <app-contact-section></app-contact-section>
</section>

