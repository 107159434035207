import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private loginMenuSubject: BehaviorSubject<boolean>;
  public loginMenu: Observable<boolean>;
  constructor() {
    this.loginMenuSubject = new BehaviorSubject<boolean>(false);
    this.loginMenu = this.loginMenuSubject.asObservable();
   }

  public open() {
    this.loginMenuSubject.next(true);
  }
  public close() {
    this.loginMenuSubject.next(false);
  }
  public get value() {
    return this.loginMenuSubject.value;
  }
}
