<div id="about-section" class="best-sellers-wrapper" [ngClass]="{'ar': translate.currentLang === 'ar'}">

  <div class="content">
    <header>
      <h2>About Us</h2>
    </header>

    <div class="best-sellers">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>Integrity Solutions - an international company that has been meeting the growing needs of clients looking
              for
              project development. Today we remain true to our original vision delivering to our client’s worldwide
              tailored
              solutions that meet their needs and make their project a reality.</p>
            <p>Integrity Solutions offer the consultancy and deep knowledge necessary to help our clients obtain the
              developing
              they need for their project. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <header>
      <h2>OUR MISSION</h2>
    </header>
    <div class="best-sellers">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>To Guarantee Successful Implementations Of Our Obligations In The Course Of Cooperation With Our Partners
              And Customers Using Our Company’s Experience And High Skills Of Our Specialists.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
