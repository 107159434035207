<div class="clients-wrapper" id="what-we-do">
  <header>
    <h2> What we do for you ??!</h2>
  </header>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h3>Consulting & Business Solutions </h3>
        <p class="sub-header">
          - “Get Your Project off the Ground”
        </p>
        <p>
          Looking to develop your business but not sure where to turn? Need help planning or executing your next
          project? Let us guide you with consulting. We understand that it is necessary to immediately organize and
          manage all available and relevant information in order to move a promising transaction toward closure. For the
          serious participant, an expert hand is a valuable and limited commodity - and for a plausible deal to become a
          completed transaction, time is of the essence.
        </p>
      </div>
      <div class="col-md-6">
        <h3>Development & Project Management </h3>
        <p class="sub-header">
          - “Achieve Your Goal”
        </p>
        <p>
          Based on our experience, we view each project. We are very active in structuring and consolidation strategies
          in order to add value for each project.
        </p>
      </div>
    </div>
  </div>
</div>
