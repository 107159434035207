<div class="best-sellers-wrapper">

  <div class="content">
    <header>
      <h2>Entertainment & Mixed Real Estate Projects </h2>
    </header>

    <div class="best-sellers">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>Some of the most interesting projects in the world are ENTERTAINMENT and RESORTS - but great projects
              need a great structure, and that is Integrity Solutions’ strength. With our professional team and field of
              knowledge , we have provided customized consultants for developing such interesting projects.</p>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
