import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

// home page
import { HomeComponent } from './home/home.component';
//home sections
import { HeroSectionComponent } from './home/hero-section/hero-section.component';
import { WhatWeDoSection } from './home/what-we-do-section/what-we-do-section.component';
import { ContactSectionComponent } from './home/contact-section/contact-section.component';

import { ComponentsModule } from '../components/components.module';
import { RouterModule } from '@angular/router';
import { RequestSuccessfulComponent } from './request-successful/request-successful.component';
import { Error404Component } from './error404/error404.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AboutSectionComponent } from './home/about-section/about-section.component';
import { ActivitiesSectionComponent } from './home/activities-section/activities-section.component';
import { EgyptComponent } from './egypt/egypt.component';
import { MoroccoComponent } from './morocco/morocco.component';
import { AboutEgyptComponent } from './egypt/about-egypt/about-egypt.component';
import { WeDoEgyptComponent } from './egypt/we-do-egypt/we-do-egypt.component';
import { ActivitiesEgyptComponent } from './egypt/activities-egypt/activities-egypt.component';
import { heroComponent } from './egypt/hero/hero.component';

const components = [
  HomeComponent,
  // home sections
  HeroSectionComponent,
  AboutSectionComponent,
  WhatWeDoSection,
  ContactSectionComponent,
  RequestSuccessfulComponent,
  Error404Component,


];
@NgModule({
  declarations: [...components, ActivitiesSectionComponent, EgyptComponent, MoroccoComponent, AboutEgyptComponent, WeDoEgyptComponent, ActivitiesEgyptComponent,heroComponent],
  imports: [
    SharedModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [...components],
})
export class PagesModule {}
