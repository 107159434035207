<div class="clients-wrapper">
  <header>
    <h2> Integrity Solutions Key Elements </h2>
  </header>

  <div class="container">
    <div class="row">
      <div class="col-md-4 text-center">
        <h4>1-Master Plan </h4>
      </div>
      <div class="col-md-4 text-center">
        <h4>2-Business Solution & Phase Development </h4>
      </div>
      <div class="col-md-4 text-center">
        <h4>3-Location & Branding</h4>
      </div>
    </div>
    <br>
    <div class="row">

    </div>
  </div>

</div>
