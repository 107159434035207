import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EgyptComponent } from 'src/app/pages/egypt/egypt.component';

import { HomeComponent } from 'src/app/pages/home/home.component';
import { MoroccoComponent } from 'src/app/pages/morocco/morocco.component';
import { MainLayoutComponent } from './main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: HomeComponent},
      { path: 'home', component: HomeComponent},
      { path: 'home/:id', component: HomeComponent},
      { path: 'entertainment-projects', component: EgyptComponent },
      { path: 'investment-opportunities', component: MoroccoComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainLayoutRoutingModule { }
