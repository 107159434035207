import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-morocco',
  templateUrl: './morocco.component.html',
  styleUrls: ['./morocco.component.scss']
})
export class MoroccoComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    animateOut: true,
    animateIn: true,
    slideTransition: 'fade',
    nav: false,
    navSpeed: 700,
    navText : ['<i class=\'fa fa-chevron-left\'></i>', '<i class=\'fa fa-chevron-right\'></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  };

  customOptions2: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplaySpeed: 500,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    animateOut: true,
    animateIn: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      577: {
        items: 2,
      },
      993: {
        items: 3,
      },
      1201: {
        items: 4,
      },
    },
  };

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
