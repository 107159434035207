
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  API_URL = environment.apiUrl + `/contacts`;

  constructor(private http: HttpClient) {}


  contact(body): Observable<any>{
    return this.http.post<any>(`${this.API_URL}`, body);
  }

  mailSubscribe(email): Observable<any>{
    email.subscribe = 1
    return this.http.post<any>(`${this.API_URL}`, email);
  }


}
