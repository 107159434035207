import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  HostListener
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from 'src/app/shared';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  filterCats = true;
  searchFlag = false;
  mobileMenuFlag = false;
  loginModalFlag = false;

  isLoading$ = false;

  public searchCtrl = new FormControl();

  constructor(
    private r2: Renderer2,
    private elementRef: ElementRef,

    private headerService: HeaderService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {

    this.headerService.loginMenu.subscribe(
      res => {
        this.loginModalFlag = res;
      }
    )

  }


  onMenuTrigger(): void {
    this.mobileMenuFlag = !this.mobileMenuFlag;
  }


  changeLang(){
    if (this.translate.currentLang === 'ar') {
      this.translate.use('en');
      localStorage.setItem('magdLang', 'en');
    } else {
      this.translate.use('ar');
      localStorage.setItem('magdLang', 'ar');
    }
  }

  getBadgePotion(): string{
    return   this.translate.currentLang === 'ar' ? 'before' : 'after';
  }
}
