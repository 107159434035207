import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainLayoutRoutingModule } from './main-layout.routing';
import { MainLayoutComponent } from './main-layout.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { PagesModule } from 'src/app/pages/pages.module';


@NgModule({
  declarations: [
    MainLayoutComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    PagesModule,
    MainLayoutRoutingModule,
  ]
})
export class MainLayoutModule { }
