<div id="morocco">
  <div class="hero-wrapper" >

    <owl-carousel-o [options]="customOptions">

      <ng-template carouselSlide>
        <div class="slide-content">

          <div class="container">
              <div class="text-wrapper px-4">
                </div>
          </div>
          <picture >
            <source media="(max-width: 576px)" srcset="assets/images/inv.jpeg">
            <source media="(max-width: 768px)" srcset="assets/images/inv.jpeg" style="height:90% ;">
            <img src="assets/images/inv.jpeg" alt="camera canon capture natural view" >
          </picture>
        </div>
      </ng-template>
    </owl-carousel-o>

  </div>

  <div class="about-wrapper">

    <div class="content">
      <header>
        <h2>Investment Opportunities</h2>
      </header>

      <div class="best-sellers">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                Approaching and conquering investment in new markets is not simple task. How do you deal with local legislation and regulations, how do you make contacts and build relationships, and what is the right strategy to approach the local market?
              </p>
              <p> Our expert Team can direct investors to have the most worth investment opportunities.</p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="clients-wrapper">
    <header>
      <h2>Available Investment Opportunities</h2>
      <p> Our expert Team will be glad for serving with more details for any request received.</p>
    </header>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="carousal-wrapper">
            <owl-carousel-o [options]="customOptions2">
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Real Estate Opportunities</h4>
                      <ul>
                        <li>Gulf project</li>
                        <li>Europe project</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Energy & Power Opportunities</h4>
                      <ul>
                        <li>Europe – Lighting products.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Oil & Gas Opportunities</h4>
                      <ul>
                        <li>Europe project.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Health Care Opportunities</h4>
                      <ul>
                        <li>Europe – Medical Management.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Information Technology Opportunities</h4>
                      <ul>
                        <li>Europe – Engineering Services Innovation.</li>
                        <li>Europe – Banking Software Innovation.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Mining Opportunities</h4>
                      <ul>
                        <li>South America project.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="container">
                  <div class="card" >
                    <!-- <img class="card-img-top" src="assets/images/home-page/activities/act0.png" alt="Card image" style="width:100%"> -->
                    <div class="card-body">
                      <h4 class="card-title text-center">Manufacture Opportunities</h4>
                      <ul>
                        <li>North Africa project.</li>
                        <li>Gulf project.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
      <br>

    </div>

  </div>

</div>
