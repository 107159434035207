<div class="wrapper">
  <div class="container">
    <header>
      <a [routerLink]="['/']">
        <img src="assets/images/logos/logo.png" alt=""></a>
    </header>
    <div class="main">
        <div class="logo-container">
          <img src="assets/images/logos/logo-check.svg" alt="">
        </div>
          <h3 class="text-capitalize">{{heading}}</h3>
          <p class="text-capitalize">{{msg}}</p>
          <a class="btn active-btn" [routerLink]="['/']" >back to home</a>
    </div>
  </div>

</div>
