import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class heroComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    animateOut: true,
    animateIn: true,
    slideTransition: 'fade',
    nav: false,
    navSpeed: 700,
    navText : ['<i class=\'fa fa-chevron-left\'></i>', '<i class=\'fa fa-chevron-right\'></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  };
  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
