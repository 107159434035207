
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private API_URL = environment.apiUrl + `/maintenance`;

  constructor(private http: HttpClient) {}


  request(body): Observable<any>{
    return this.http.post<any>(`${this.API_URL}`, body);
  }

}
