<router-outlet>

</router-outlet>

<ngx-spinner
  bdColor="rgba(47, 95, 160, 0.5)"
  template="<img src='../assets/images/logos/logo-white.svg' />"
>
</ngx-spinner>
<!-- <div [ngClass]="{'ar-class': translate.currentLang == 'ar'}">
  <h2>{{ 'HOME.TITLE' | translate }}</h2>
  <label>
    {{ 'HOME.SELECT' | translate}}
    <select #langSelect (change)="translate.use(langSelect.value)">
      <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
    </select>
  </label>
</div> -->
