import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/shared';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent implements OnInit {

  contactForm: FormGroup;
  isSubmitted = false;
  isLoading$ = false;
  errorMsg: string = null;

  constructor(
    private docTitle: Title,
    private fb: FormBuilder,
    private contactService: ContactService,
    private router: Router,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {

    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      body: ['', [Validators.required]]
    });
  }

  public get nameCtrl(): FormControl{
    return this.contactForm.get('name') as FormControl;
  }
  public get emailCtrl(): FormControl{
    return this.contactForm.get('email') as FormControl;
  }
  public get bodyCtrl(): FormControl{
    return this.contactForm.get('body') as FormControl;
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isLoading$ = true;
    this.contactForm.valid &&
    this.contactService.contact(this.contactForm.value)
    .subscribe(done =>{
      this.isLoading$ = false;
      this.router.navigate(['/requested-successfully/Sent Successfully/we have received your inquiry and we will contact with you soon']);
    },
    error => {
      this.isLoading$ = false;
    })
  }
}
