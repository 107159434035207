import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './pages/error404/error404.component';
import { RequestSuccessfulComponent } from './pages/request-successful/request-successful.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./layouts/main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./layouts/auth-layout/auth-layout.module').then(
        (m) => m.AuthLayoutModule
      ),
  },
  { path: 'requested-successfully/:h/:msg', component: RequestSuccessfulComponent },
  // { path: '', pathMatch: 'full', redirectTo: 'home' },
  // { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling:'enabled',
    scrollOffset: [0, 64]
    })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
