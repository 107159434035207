

<div class="subscribe-wrapper">
  <div class="container">
    <div class="row ">
      <div class="col-lg-4 col-md-5 col-xs-12">
        <div class="logo-container">
          <img src="assets/images/logos/logoo.svg" alt="">
        </div>
      </div>
      <div class="col-lg-4 col-md-2 col-xs-0"></div>
      <div class="col-lg-4 col-md-5 col-xs-12">
        <div class="form-group news-letter-input">
          <form (ngSubmit)="onSubmit()" [formGroup]="subscribeForm">
            <div class="form-group">
              <label for="subscribe-input">{{'GENERAL.NEWSLETTER' | translate}}</label>
              <ng-container *ngIf="isLoading$; else elseSubscribe">
                <br>
                <div class="spinner-grow text-muted-lg"></div>
                <br>
              </ng-container>
              <ng-template #elseSubscribe>

                <input type="email" class="form-control" name="email" id="subscribe-input" formControlName="email"
                  [ngClass]="{'is-invalid': emailCtrl.invalid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)}"
                  [ngClass]="{'is-valid': emailCtrl.valid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)}">
                <div *ngIf="emailCtrl.valid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)"
                  class="valid-feedback">{{'GENERAL.VALID_MAIL' | translate}}</div>
                <div *ngIf="emailCtrl.errors && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)"
                  class="invalid-feedback">
                  <div *ngIf="emailCtrl.errors?.required">{{'GENERAL.REQ_ERROR' | translate}}</div>
                  <div *ngIf="emailCtrl.errors?.email">
                    {{'GENERAL.VALID_MAIL' | translate}} <span class="text-lowercase">example@mail.com</span>.
                  </div>
                </div>

              </ng-template>
            </div>
          </form>
          <ng-container *ngIf="errorMsg || successMsg; else elseHelpText">
            <small *ngIf="errorMsg;" id="helpId" class="invalid-feedback"
              style="display: block; word-break: break-all;"
            >{{errorMsg}}</small>
            <small *ngIf="successMsg;" id="helpId" class="valid-feedback">{{successMsg}}</small>
          </ng-container>
          <ng-template #elseHelpText>
            <small id="helpId" class="form-text text-muted">{{'GENERAL.SUBSCRIBE' | translate}}</small>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-wrapper" [ngClass]="{'ar': translate.currentLang === 'ar'}">
  <div class="container">
    <div class="row site-map">
      <div class="col-lg-4 col-md-4 col-xs-12 ">
        <div class="products">
          <h2>Our Services</h2>
          <ul>
            <li>Real Estate Projects</li>
            <li>Power & Energy</li>
            <li>Oil & Gas</li>
            <li>Information Technology</li>
            <li>Healthcare & Wellness</li>
            <li>Sewage Treatment</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12 ">
        <!-- <div class="solutions">
          <h2>SOLUTIONS</h2>
          <ul>
            <li>{{'GENERAL.BENEFITS_CLOUD' | translate}}</li>
            <li>{{'GENERAL.REVENUE_CLOUD ' | translate}}</li>
          </ul>
        </div> -->
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="contact">
          <h2>{{'GENERAL.CONTACT_US' | translate}}</h2>
          <ul>
            <li class="text-lowercase">Info@integritysolutions-intl.com</li>
            <!-- <li >0100000000</li>
            <li>0200000000</li>
            <li>0200000000</li> -->
          </ul>
        </div>
      </div>
    </div>
    <hr>
    <p class="w-100 d-flex align-items-center justify-content-center m-0 pb-3 px-2">Copy Rights &copy; Integrity Solutions. </p>
    <!-- <div class="socials">
      <a href=" https://www.facebook.com/elmagdtecno">
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </a>
      <a href="https://twitter.com/home?lang=en ">
        <i class="fa fa-twitter" aria-hidden="true"></i>
      </a>
      <a href="https://www.instagram.com/elmagdtecno/ ">
        <i class="fa fa-instagram" aria-hidden="true"></i>
      </a>
      <a href="https://www.linkedin.com/company/75542771/admin/">
        <i class="fa fa-linkedin" aria-hidden="true"></i>
      </a>
    </div> -->
  </div>
</div>
