import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-egypt',
  templateUrl: './egypt.component.html',
  styleUrls: ['./egypt.component.scss']
})
export class EgyptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
