<div class="partners-wrapper" id="contact">
  <section class="contact-quote">

    <div class="header-anchor">
      <div class="header-container">
        <header>
          <h2>{{'CONTACT.HEADER' | translate}}</h2>
          <p>{{'CONTACT.QUOTE' | translate}}</p>
        </header>
      </div>
    </div>

    <article class="w-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="row">
              <div class="col-12">
                <div class="connections">
                  <h3>{{'CONTACT.CONNECTIONS' | translate}}</h3>
                  <ul>
                    <li class="mb-3">
                      <h4>email</h4>
                      <a class="text-lowercase" href="mailto:abouelelaa.hassan@almagdtrading.com ">
                        Info@integritysolutions-intl.com
                      </a>
                      <br>
                    </li>
                    <!-- <li>
                      <h4>phone</h4>
                      <a href="tel:+2001228806777">0100000000</a>
                      <br>
                      <a href="tel:+20223908947">011111111111</a>
                      <br>
                      <a href="tel:+20223908948">011111111111</a>
                    </li> -->
                  </ul>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="locations">
                  <h3>{{'CONTACT.LOCATIONS' | translate}}</h3>
                  <ul>
                    <li>
                      <h4>{{'CONTACT.OFFICE.OFFICE' | translate}}</h4>
                      <address>
                        Head office 54 some address, some block, some city, some country. </address>
                    </li>
                    <li>
                      <h4>{{'CONTACT.BRANCH.BRANCH' | translate}}</h4>
                      <address>
                        Head office 54 some address, some block, some city, some country. </address>
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-wrapper">
              <h3>{{'CONTACT.QUESTION' | translate}}</h3>

              <form [formGroup]='contactForm'>
                <div class="form-group">
                  <label for="name-ctrl">{{'GENERAL.NAME' | translate}}</label>
                  <input type="text" class="form-control" name="name" id="name-ctrl" placeholder=""
                    formControlName="name"
                    [ngClass]="{'is-invalid': nameCtrl.invalid && (isSubmitted || nameCtrl.dirty || nameCtrl.touched)}"
                    [ngClass]="{'is-valid': nameCtrl.valid && ( nameCtrl.dirty || nameCtrl.touched)}">
                  <div *ngIf="nameCtrl.valid && (nameCtrl.dirty || nameCtrl.touched)" class="valid-feedback">
                    {{'GENERAL.VALID' | translate}}</div>
                  <div *ngIf="nameCtrl.invalid && (isSubmitted || nameCtrl.dirty || nameCtrl.touched)"
                    class="invalid-feedback">{{'GENERAL.REQ_ERROR' | translate}}</div>
                </div>

                <div class="form-group">
                  <label for="email-ctrl">{{'GENERAL.EMAIL' | translate}}</label>
                  <input type="email" class="form-control form-control-lg" name="" id="email-ctrl" placeholder=""
                    formControlName="email"
                    [ngClass]="{'is-invalid': emailCtrl.invalid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)}"
                    [ngClass]="{'is-valid': emailCtrl.valid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)}">
                  <div *ngIf="emailCtrl.valid && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)"
                    class="valid-feedback">{{'GENERAL.VALID' | translate}}</div>
                  <div *ngIf="emailCtrl.errors && (isSubmitted || emailCtrl.dirty || emailCtrl.touched)"
                    class="invalid-feedback">
                    <div *ngIf="emailCtrl.errors?.required">{{'GENERAL.REQ_ERROR' | translate}}</div>
                    <div *ngIf="emailCtrl.errors?.email">
                      {{'GENERAL.VALID_MAIL' | translate}} <span class="text-lowercase">example@mail.com</span>.
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="message-ctrl">{{'GENERAL.MESSAGE' | translate}}</label>
                  <textarea class="form-control" name="message" id="message-ctrl" rows="6" formControlName="body"
                    [ngClass]="{'is-invalid': bodyCtrl.invalid && (isSubmitted || bodyCtrl.dirty || bodyCtrl.touched)}"
                    [ngClass]="{'is-valid': bodyCtrl.valid && ( bodyCtrl.dirty || bodyCtrl.touched)}"></textarea>
                  <div *ngIf="bodyCtrl.valid && (bodyCtrl.dirty || bodyCtrl.touched)" class="valid-feedback">
                    {{'GENERAL.VALID' | translate}}</div>
                  <div *ngIf="bodyCtrl.invalid && (isSubmitted || bodyCtrl.dirty || bodyCtrl.touched)"
                    class="invalid-feedback">{{'GENERAL.REQ_ERROR' | translate}}</div>
                </div>

                <div class="form-group">
                  <input class="btn" type="reset" [value]="'GENERAL.CLEAR' | translate">
                  <button class="btn submit-btn" (click)="onSubmit()" *ngIf="!isLoading$">{{'GENERAL.SEND' |
                    translate}}</button>
                  <button class="btn submit-btn spinner" *ngIf="isLoading$">
                    <span class="spinner-border text-light"></span>
                  </button>
                </div>
              </form>

            </div>
          </div>

        </div>
      </div>
    </article>
  </section>
</div>
