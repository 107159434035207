import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoroccoComponent } from 'src/app/pages/morocco/morocco.component';
import { RequestSuccessfulComponent } from 'src/app/pages/request-successful/request-successful.component';

const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthLayoutRoutingModule { }
