<div class="wrapper">
    <div class="hero-wrapper" [ngClass]="{'ar': translate.currentLang === 'ar'}">



      <owl-carousel-o [options]="customOptions">

        <ng-template carouselSlide>
          <div class="slide-content">

            <div class="container">
                <div class="text-wrapper px-4">

                    <!-- <h2 style="justify-content: center;">{{'HOME.SLIDES.EGYPT' | translate}}</h2> -->
                  </div>
            </div>
            <picture >
              <source media="(max-width: 576px)" srcset="assets/images/home-page/hero/puzzlee.jpeg">
              <source media="(max-width: 768px)" srcset="assets/images/home-page/hero/puzzlee.jpeg" style="height:90% ;">
              <img src="assets/images/home-page/hero/puzzlee.jpeg" alt="camera canon capture natural view" >
            </picture>


          </div>

        </ng-template>
        <!-- <ng-template carouselSlide>
          <div class="slide-content">
            <div class="container">
              <div class="text-wrapper">
                <h2>{{'HOME.SLIDES.TITLE2' | translate}}</h2>
                <p>
                  {{'HOME.SLIDES.TEXT2' | translate}}
                </p>
              </div>
            </div>
            <picture>
              <source media="(max-width: 576px)" srcset="assets/images/home-page/hero/tab/pexels-sebastian-voortman-411195.png">
              <source media="(max-width: 768px)" srcset="assets/images/home-page/hero/tab/pexels-sebastian-voortman-411195.png">
              <img src="assets/images/home-page/hero/power-enrgy.png" alt="camera canon capture natural view">
            </picture>
          </div>
        </ng-template>
         -->
      </owl-carousel-o>

    </div>

  </div>
